import { Injectable, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { setCulture } from '../redux/cultureActions';
import { ReduxStore } from 'ngx-myia-redux';
import { LOCALIZATION_CONFIGURATION } from '../localizationConfig';
import { ILocalizationConfig } from '../ILocalizationConfig.interface';
import { cultureReducerKey, ICultureState } from '../redux/cultureReducers';
import { Logger } from 'ngx-myia-core';

@Injectable({providedIn: 'root'})
export class CultureService {

    onChange: EventEmitter<string>;

  constructor(private _store: ReduxStore, private _translateService: TranslateService, @Inject(LOCALIZATION_CONFIGURATION) private _configuration: ILocalizationConfig, private _logger: Logger) {
    this.onChange = new EventEmitter();
  }

    get currentCulture() {
    return this._translateService.currentLang;
  }

    initialize() {
    this.getStoredCulture().pipe(
      map((culture) => {
        if (!culture) {
          culture = this.getDefaultLang();
        }
        this._translateService.setDefaultLang(culture); // this language will be used as a fallback when a translation isn't found in the current language
        return culture;
      }),
      mergeMap(culture => {
        return this.use(culture);
      })
    ).subscribe();
  }

    use(culture: string): Observable<void> {
    // CultureService.putLanguageToStorage(culture);
    return this._translateService.use(culture).pipe(
      tap(() => {
        this._store.dispatch(setCulture(culture));
        this.onChange.emit(culture);
      })
    );
  }

    getSupportedCultures(): Array<string> {
    return this._configuration.supportedCultures;
  }

  switchCulture(culture: string) {
    if (culture && culture !== this.currentCulture && this.getSupportedCultures().find(c => c === culture)) {
      this.use(culture);
    }
  }

    private getStoredCulture(): Observable<string | undefined> {
        return new Observable((observer: Observer<string | undefined>) => {
      const cultureState: ICultureState = this._store.getState(cultureReducerKey);
      if (cultureState) {
        let isRehydrating = cultureState._isRehydrating;
        if (isRehydrating) {
          // wait for result
          this._logger.log('Waiting for rehydrated culture ...');
          const sub = this._store.subscribe((state: any) => {
            const currentCultureState: ICultureState = state[cultureReducerKey];
            isRehydrating = currentCultureState._isRehydrating;
            if (!isRehydrating) {
              sub(); // release store subscription
              observer.next(currentCultureState.culture);
              observer.complete();
            }
          }, [cultureReducerKey]);
        } else {
          observer.next(cultureState.culture);
          observer.complete();
        }
      } else {
        this._logger.info('stored culture not found.');
                observer.next(undefined);
        observer.complete();
      }
    }).pipe(
            map((userLang: string | null) => {
        if (!userLang) {
          // use browser language
          userLang = navigator.language ? navigator.language.split('-')[0] : null; // use navigator lang if available
        }
        // check supported cultures
                userLang = !userLang || !this._configuration.supportedCultures || this._configuration.supportedCultures.indexOf(userLang) === -1 ? this.getDefaultLang() : userLang;
        return userLang;
      })
    );
  }

  private getDefaultLang(): string {
    return this._configuration && this._configuration.defaultCulture ? this._configuration.defaultCulture : 'en';
  }

}
