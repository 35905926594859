import { Injectable, OnDestroy } from '@angular/core';
import * as moment_ from 'moment';

const moment = moment_;
import { CultureService } from './cultureService';
import { Observable, of, Observer } from 'rxjs';
import { map } from 'rxjs/operators';


const UNITS : { [key:string]: any } = {
  seconds: {
    patterns: ['second', 'sec', 's'],
    value: 1
  },
  minutes: {
    patterns: ['minute', 'min', 'm(?!s)'],
    value: 60
  },
  hours: {
    patterns: ['hour', 'hr', 'h'],
    value: 3600
  },
  days: {
    patterns: ['day', 'dy', 'd'],
    value: 86400
  }
};


@Injectable({providedIn: 'root'})
export class FormatDateService implements OnDestroy {
  private _onCultureChange: any;
  private _momentDurationFormatLoaded: boolean;
  private _loadedCultures: Array<string> = [];

  constructor(private _cultureService: CultureService) {
    // subscribe to onChange event, in case the culture changes
    this._onCultureChange = this._cultureService.onChange.subscribe((currentCulture: string) => {
      this.loadMomentCulture(currentCulture);
    });
    if (_cultureService.currentCulture) {
      this.loadMomentCulture(_cultureService.currentCulture);
    }
  }

  ngOnDestroy() {
    if (this._onCultureChange) {
      this._onCultureChange.unsubscribe();
      this._onCultureChange = undefined;
    }
  }

  getFormattedDate(date: Date | string, options?: { format?: string, locale?: string, convertToLocalDate?: boolean }): string {
    const opt = {
      ...options
    };
    const momentDate: any = opt.convertToLocalDate ? moment(date).local() : moment(date);
    if (opt.locale) {
      momentDate.locale(opt.locale);
    }
    return opt.format ? (momentDate[opt.format] ? momentDate[opt.format]() : momentDate.format(opt.format)) : momentDate.format('LLL');
  }

  getFormattedDuration(seconds: number, format: string): Observable<string> {
    return this.loadMomentDurationLib().pipe(map(() => {
      return (moment.duration(seconds, 's') as any).format(format, {trim: false});
    }));
  }


  getDurationFromSeconds(seconds: number): Observable<string> {
    // const format = seconds > 3600*24 ? 'd[d] h[h] m[m]' : (seconds >= 3600  ? 'h[h] m[m]' : ('m[m] s[s]'));
    let format = '';
    if (seconds >= 3600 * 24) {
      format += 'd[d] ';
    }
    if (seconds >= 3600 && seconds % (3600 * 24)) {
      format += 'h[h] ';
    }
    if (seconds >= 60 && seconds % 3600) {
      format += 'm[m] ';
    }
    if (seconds % 60) {
      format += 's[s]';
    }
    if (!seconds) {
      return of('');
    }
    return this.loadMomentDurationLib().pipe(map(() => {
      return (moment.duration({seconds}) as any).format({template: format.trim()});
    }));
  }

  getDurationPartsFromSeconds(seconds: number): Observable<{d: number, h: number, m: number, s: number}> {
    if (!seconds) {
      return of({d: 0, h: 0, m: 0, s: 0});
    }
    return this.loadMomentDurationLib().pipe(map(() => {
      const dif = moment.duration({seconds});
      return {
        d: Math.floor(dif.asDays()),
        h: dif.hours(),
        m: dif.minutes(),
        s: dif.seconds()
      };
    }));
  }

  getDurationFromDates(date1: Date | string, date2: Date | string): number {
    const duration = moment.duration(this.parseDate(date2).diff(date1));
    return duration.asSeconds();
  }

  getTimeFromNow(date: Date): Observable<string | null> {
    const durationInSeconds = moment.duration(moment(date).diff(Date())).asSeconds();
    return durationInSeconds >= 1 ? this.getDurationFromSeconds(durationInSeconds) : of(null);
  }

  getDurationFromISOString(durationISOString: string): number {
    return moment.duration(durationISOString).asSeconds();
  }

  getDurationFromString(durationString: string): number {
    // returns calculated values separated by spaces
    for (const unit in UNITS) {
      if (UNITS.hasOwnProperty(unit)) {
        for (let i = 0, mLen = UNITS[unit].patterns.length; i < mLen; i++) {
          const regex = new RegExp('((?:\\d+\\.\\d+)|\\d+)\\s?(' + UNITS[unit].patterns[i] + 's?(?=\\s|\\d|\\b))', 'gi');
          durationString = durationString.replace(regex, (str, p1) => {
            return ' ' + (p1 * UNITS[unit].value).toString() + ' ';
          });
        }
      }
    }

    let sum = 0;
    const numbers = durationString
      .replace(/(?!\.)\W+/g, ' ')                       // replaces non-word chars (excluding '.') with whitespace
      .replace(/^\s+|\s+$|(?:and|plus|with)\s?/g, '')   // trim L/R whitespace, replace known join words with ''
      .split(' ');

    for (let j = 0, nLen = numbers.length; j < nLen; j++) {
      if (numbers[j] && isFinite(numbers[j] as any)) {
        sum += parseFloat(numbers[j]);
      } else if (!numbers[j]) {
        throw new Error('getDurationFromString: Unable to parse: a falsey value');
      } else {
        // throw an exception if it's not a valid word/unit
        throw new Error('getDurationFromString: Unable to parse: ' + numbers[j].replace(/^\d+/g, ''));
      }
    }
    return sum;
  }

  parseLocalDate(strDate: string | Date, convertToDate?: boolean): moment_.Moment | Date | null {
    if (!strDate) {
      return null;
    }
    const result = moment(strDate, this.getCurrentLocalDateFormat());
    if (convertToDate) {
      return result.toDate();
    }
    return result;
  }

  parseDate(strDate: string | Date): moment_.Moment {
    return moment(strDate);
  }

  parseTime(strTime: string): moment_.Moment {
    return moment(strTime, this.getCurrentLocalTimeFormat());
  }

  getUnixTime(strDate: string | Date): number {
    return moment(strDate).unix();
  }

  isThisYear(date: string): boolean {
    return moment(date).year() === moment().year();
  }

  now() {
    return moment();
  }

  isDateBetween(date: any, start: any, end: any, inclusivity: '()' | '[)' | '(]' | '[]' = '()'): boolean {
    return moment(date).isBetween(start, end, null, inclusivity);
  }

  dateRangesOverlaps(date1Start: any, date1End: any, date2Start: any, date2End: any): boolean {
    return this.isDateBetween(date1Start, date2Start, date2End, '[)') || this.isDateBetween(date1End, date2Start, date2End, '(]');
  }

  isDateAfter(date: any, compareTo: any): boolean {
    return moment(date).isAfter(compareTo);
  }

  isDateValid(strDate: string): boolean {
    return moment(strDate, this.getCurrentLocalDateFormat()).isValid();
  }

  isTimeValid(strTime: string): boolean {
    return moment(strTime, this.getCurrentLocalTimeFormat()).isValid();
  }

  getLaterDate(date: any, compareTo: any): Date {
    return moment.max(moment(date), moment(compareTo)).toDate();
  }

  getCurrentLocalDateFormat(): string {
    let result;
    switch (this._cultureService.currentCulture) {
      case 'cs':
        result = 'D.M.YYYY H:mm';
        break;
      default:
        result = 'YYYY-MM-DD h:mm A';
        break;

    }
    return result;
  }

  getCurrentLocalDateOnlyFormat(): string {
    let result;
    switch (this._cultureService.currentCulture) {
      case 'cs':
        result = 'D.M.YYYY';
        break;
      default:
        result = 'YYYY-MM-DD';
        break;

    }
    return result;
  }

  getCurrentLocalTimeFormat(options?: {withZeroPadding: boolean}): string {
    let result;
    switch (this._cultureService.currentCulture) {
      case 'cs':
        result = options && options.withZeroPadding ? 'HH:mm' : 'H:mm';
        break;
      default:
        result = options && options.withZeroPadding ? 'hh:mm A' : 'h:mm A';
        break;

    }
    return result;
  }

  getLocalDateTimeFromISO8601String(date: string): any {
    return moment(date, moment.ISO_8601);
  }

  getLocalDateTimeString(date: any): string {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  getTimeString(date: any, options?: {withZeroPadding: boolean}): string {
    return moment(date).format(this.getCurrentLocalTimeFormat(options));
  }

  getShortDateTime(date: string) {
    let result;
    const mDate = moment(date);
    const isToday = mDate.isSame(this.now(), 'day');
    switch (this._cultureService.currentCulture) {
      case 'cs':
        result = mDate.format(isToday ? 'LT' : 'Do MMM LT');
        break;
      default:
        result = mDate.format(isToday ? 'LT' : 'MMM Do LT');
        break;

    }
    return result;
  }

  getTimeline(fromDate: string | undefined, toDate: string | undefined, stepInMinutes: number, onlyFrom: boolean, mustIncludeDateStrings: Array<{ time: string | undefined, hidden?: boolean }>): Array<{ id: string, from: Date, to: Date, text: string, hidden: boolean } | null> {
    if (!fromDate || !toDate) {
      return [];
    }
    const from = this.getLocalDateTimeFromISO8601String(fromDate);
    const to = this.getLocalDateTimeFromISO8601String(toDate);
    mustIncludeDateStrings = mustIncludeDateStrings || [];
    mustIncludeDateStrings.push({time: fromDate}, {time: toDate});
    if (stepInMinutes) {
      let current = from;
      while (current < to) {
        const stepTo = current.clone().add(stepInMinutes, 'minutes');
        mustIncludeDateStrings.push({time: stepTo.format('YYYY-MM-DD HH:mm')});
        current = stepTo;
      }
    }
    // only distinct dates
    mustIncludeDateStrings = mustIncludeDateStrings.sort((x, y) => {
      const sameTimes = x.time && y.time ? x.time.localeCompare(y.time) : -1;
      // hidden times to the end
      if (sameTimes === 0) {
        return x.hidden ? 1 : -1;
      }
      return sameTimes;
    })
      .filter((value, index, self) => {
        return self.findIndex(tm => tm.time === value.time) === index;
      });

    const mustIncludeDates = mustIncludeDateStrings.map(d => {
      return {time: d.time ? this.getLocalDateTimeFromISO8601String(d.time) : undefined, hidden: d.hidden};
    });

    const timeFormat = 'LT';
    return mustIncludeDates.map((d, index, self) => {
      const stepFrom = d;
      const stepTo = index < self.length - 1 ? self[index + 1] : null;
      return stepTo ? {
        id: stepFrom.time.format(timeFormat),
        from: stepFrom.time.toDate(),
        to: stepTo.time.toDate(),
        text: onlyFrom ? stepFrom.time.format(timeFormat) : (stepFrom.time.format(timeFormat) + ' - ' + stepTo.time.format(timeFormat)),
        hidden: stepFrom.hidden ?? false
      } : null;
    }).filter(r => r);
    // const timeLine = [];
    // current = from;
    // while (current < to) {
    //     const stepFrom = current;
    //     let stepSize = stepInMinutes;
    //     if (mustIncludeDates && mustIncludeDates.length && (mustIncludeDates[0] - stepFrom) <= stepInMinutes) {
    //         stepSize = Math.floor((mustIncludeDates[0] - stepFrom) / 1000 / 60);
    //         mustIncludeDates.shift();
    //     }
    //     const stepTo = current.clone().add(stepSize, 'minutes');
    //     timeLine.push({
    //         from: stepFrom.toDate(),
    //         to: stepTo.toDate(),
    //         text: onlyFrom ? stepFrom.format(timeFormat) : (stepFrom.format(timeFormat) + ' - ' + stepTo.format(timeFormat))
    //     });
    //     current = stepTo;
    // }
    // return timeLine;
  }

  private loadMomentCulture(currentCulture: string) {
    if (this._loadedCultures.indexOf(currentCulture) === -1) {
      this._loadedCultures.push(currentCulture);
      switch (currentCulture) {
        case 'cs':
          (require as any).ensure([], () => {
            require('moment/locale/cs');
            moment.locale(currentCulture);
            this.updateView();
          });
          break;
        case 'en':
          (require as any).ensure([], () => {
            require('moment/locale/en-gb');
            moment.locale(currentCulture);
            this.updateView();
          });
          break;
        default:
          throw new Error(`Culture ${currentCulture} moment.js mapping is missing!`);
      }
    } else {
      moment.locale(currentCulture);
    }
  }

  private loadMomentDurationLib(): Observable<void | null> {
    return this._momentDurationFormatLoaded ? of(null) : new Observable((observer: Observer<void>) => {
      (require as any).ensure([], () => {
        require('moment-duration-format');
        moment.locale(this._cultureService.currentCulture); // set current culture again to revert 'en' set by moment-duration-format when loaded
        this._momentDurationFormatLoaded = true;
        observer.next();
        observer.complete();
      });
    });

  }

  private updateView() {
    // raise event again to update all view
    this._cultureService.onChange.emit(this._cultureService.currentCulture);
  }
}
