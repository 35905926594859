import { Observable } from 'rxjs';

import { Injector } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';

import { LOCALIZATION_CONFIGURATION } from './localizationConfig';

export class TranslateLoaderWrapper implements TranslateLoader {
    private _loader: TranslateLoader;

    constructor(private _injector: Injector) {}

    getTranslation(lang: string): Observable<any> {
        if (!this._loader) {
            const translateLoaderFactory = this._injector.get(
                LOCALIZATION_CONFIGURATION
            ).translateLoaderFactory;
            if (!translateLoaderFactory) {
                throw new Error(
                    'TranslateLoaderFactory configuration not found.'
                );
            }
            this._loader = translateLoaderFactory();
        }
        return this._loader.getTranslation(lang);
    }
}
